<template>
  <div>
    <div :class="isPagination ? 'tableBlock' : ''"   :style="isPagination ? 'min-height: 528px': ''">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#F4F8FC',
          color: '#626466',
          fontSize: '14px',
          fontWeight: 'normal',
        }"
        :cell-style="isPagination ? { color: '#333333', fontSize: '14px', backgroundColor:'#fff' } : { color: '#333333', fontSize: '14px', backgroundColor:'#FAFBFC' }"
        :stripe="stripe"
        :height="height"
        @selection-change="handleClick"
        :row-key="getRowKeys"
        :expand-row-keys="expands"
        @expand-change="changeExpand"
        :row-class-name="_tableRowClassName"
        :show-summary="show_summary"
        :summary-method="getSummaries"
      
      >
         <template v-if="expand && expand.length > 0">
            <el-table-column
            class="expand_table"
            :type="item.type"
            v-for="item in expand"
            :key="item.type"
            :label="item.label"
            :align="item.align"
            :selectable="item.selectable"
            width="50"
          >
            <template slot-scope="scope">
              <div v-if="item.slot">
                <slot :name="item.slot" :scope="scope" ></slot>
              </div>
            </template>
          </el-table-column>

        </template>     
        <template v-if="extend && extend.length > 0">
          <el-table-column
            v-for="(item, index) in extend"
            :key="index"
            :type="item.type"
            :label="item.label"
            :width="item.width"
            :align="item.align"
            :fixed="item.fixed"
          >
          </el-table-column>
        </template>

        <template v-for="(item, index) in columns" >
          <template v-if="!item.child">
            <el-table-column
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              :min-width="item.minWidth"
              :align="item.align"
              :fixed="item.fixed"
             
            >
              <template #default="scope">
                <slot  :class="item.class" v-if="item.slot" :name="item.slot" :scope="scope"></slot>
                <span   :class="item.class" v-else >{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </template>
          <template v-else>
            <el-table-column
              :label="item.label"
              :width="item.width"
              :min-width="item.minWidth"
              :align="item.align"

            >
              <el-table-column
                v-for="(itemChild, indexChild) in item.child"
                :key="indexChild"
                :prop="itemChild.prop"
                :label="itemChild.label"
                :width="itemChild.width"
                :min-width="itemChild.minWidth"
                :align="itemChild.align"
              >
                <template #default="scope">
                  <slot
                    v-if="itemChild.slot"
                    :name="itemChild.slot"
                    :scope="scope"
                  ></slot>
                  <span v-else>{{ scope.row[itemChild.prop] }}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </template>
        </template>
      </el-table>
    </div>

    <div class="paginationBlock" v-if="isPagination" >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "",
  props: {
    tableRowClassName: {
      type: Function,
    },
    // 数据
    tableData: {
      type: Array,
      required: true,
    },
    // 表格配置项
    columns: {
      type: Array,
      required: true,
    },
    // 边框
    border: {
      type: Boolean,
      default: false,
    },
    // 表格尾部合计
    show_summary: {
      type: Boolean,
      default: false,
    },
    // 表格高度
    height: {
      type: String,
    },
    // 是否合计
    stripe: {
      type: Boolean,
      default: false,
    },
    // 斑马纹
    stripe: {
      type: Boolean,
      default: false,
    },
    // 扩展项
    extend: {
      type: Array,
    },
    expand: {
      type: Array,
    },
    check: {
      type: Boolean,
      default: false,
    },
    sumList: {
      type: Array,
    },
    // 数据总量
    total: {
      type: Number,
    },
    // 是否显示分页器
    isPagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      getRowKeys(row) {
        return row.id;
      },
      expands: [],
    };
  },
  components: {},
  methods: {
    _tableRowClassName({ row, rowIndex }) {
      if (row.isTrue) {
        return this.tableRowClassName(row, rowIndex);
      }
    },
    //选择器的方法
    handleClick(e) {
      this.$emit("select", e);
    },
    changeExpand(e, expandedRows) {
      console.log('expandedRows',e,expandedRows)
      var that = this;
      if (expandedRows.length) {
        that.expands = [];
        if (e) {
          that.expands.push(e.id); // 每次push进去的是每行的ID
        }
      } else {
        that.expands = []; // 默认不展开
      }
      this.$emit("changeExpand", e);
    },
    // 合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      sums[0] = "合计";
      if (this.sumList.length > 0) {
        this.sumList.forEach((item) => {
          sums[parseInt(item.label - 1)] = item.value;
        });
      }
      return sums;
    },
    // 改变每页显示条数
    handleSizeChange(val) {
      this.$emit("sizeChange", val);
    },
    // 切换页数
    handleCurrentChange(val) {
      this.$emit("currentChange", val);
    },
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>
<style>
.el-table .el-table__body tr:hover > td {
  background-color: #f7f7f7 !important;
}
.el-table .el-table__body tr.current-row > td {
  background-color: #f7f7f7 !important;
}
.tableBlock{
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
}
.paginationBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 8px;
}

.el-table__cell.el-table__expanded-cell{
  padding: 0 !important;
}
</style>
  
import axios from 'axios';
import { showMessage } from './status';
import { Message } from 'element-ui'
import config from '/src/config.js'
import { pageMessage } from '@/utils/function'
import router from '@/router/index'
//设置接口超时时间
axios.defaults.timeout = 20000;

// 开发环境地址
let development =  config.baseUrl["development"]
//生产环境地址
let production =  config.baseUrl["production"]
//请求地址
axios.defaults.baseURL = `${process.env.NODE_ENV === 'production' ? production : development}`;

export const baseURL = `${process.env.NODE_ENV === 'production' ? production : development}`

//请求拦截器
axios.interceptors.request.use(
    config => {
        let token = localStorage.getItem('token');
        //配置请求头
        config.headers = {
            //'Content-Type':'application/x-www-form-urlencoded',   // 传参方式表单
            'Content-Type': 'application/json;charset=UTF-8',    // 传参方式json
            'token': token,
            "Access-Control-Allow-Origin": "*"
        };
        return config;
    },
    error => {
        return Promise.reject(error)
    }
);


//响应拦截器
let timer = null
axios.interceptors.response.use(
    response => {
        const { data } = response
        if (data.code != 0 && data.code && data.code != 600 && data.code != 601) {
            
            if(data.code == '401') {
                if(timer !== null){
                    clearTimeout(timer)
                }
                timer = setTimeout(()=>{
                    pageMessage({
                        type: 'error',
                        message: "token为空或失效，请重新登录",
                        showClose: false
                    })
                    router.push("/login");
                    this.$router.replace({ path: "/login" });
                },500)
                

            }else{
                pageMessage({
                    type: 'error',
                    message: data.message,
                    showClose: false
                })
            }
            return Promise.reject(response.data);
        }
        return data;
    },
    error => {
        const { response } = error;
        if (response) {
            //传入状态码，匹配响应码对应信息
            showMessage(response.status);
            if(response.status == '401'){
                setTimeout(()=>{
                    router.push("/login");
                    this.$router.replace({ path: "/login" });
                },1000)
            }
            return Promise.reject(response.data);
        } else {
            Message.warning('网络连接异常，请稍后再试！')
        }
    }
);



// 封装GET POST PUT DELETE 请求并导出
export function request(url = '', params = {}, type = 'POST') {
    //设置 url params type 的默认值
    return new Promise((resolve, reject) => {
        let promise
        // toUpperCase() 将字符串转化成大写
        if (type.toUpperCase() === 'GET'  || type.toUpperCase() === 'DELETE') {
            promise = axios({
                method:type,
                url,
                params,
            })
        } else if (type.toUpperCase() === 'POST' || type.toUpperCase() === 'PUT' ) {
            promise = axios({
                method: type,
                url,
                data: params
            })
        } else if (type.toUpperCase() === 'PUT') {
            promise = axios({
                method: 'PUT',
                url,
                data: params
            })
        }
        //处理返回
        promise.then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


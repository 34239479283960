<template>
    <el-dialog class="page_message_dialog"  
        :visible.sync="messageShow" 
        :width="width" 
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"	
    >
        <div class="header">
            <div class="header_left">
                <img src='@/assets/info_error.png' v-if="icon == 1">
                <img src='@/assets/info_nor.png' v-if="icon == 2">
                <img src='@/assets/info_warning.png' v-if="icon == 3">
                <div class="title">{{title}}</div>
            </div>
            <img 
                src="@/assets/iconBlack_close.png" 
                class="close"
                @click="cancelMessage"
            >
        </div>
        <div class="content">{{content}}</div>
        <div class="footer">
            <el-button 
                type="primary" 
                @click="confrimMessage"  
                class="page_form_btn"
                :style="buttonStyle"
                
            >{{ confrimText }}</el-button> 
            <el-button 
                v-if="showCancel"
                @click="cancelMessage" 
                type="primary" 
                class="page_form_btn" 
                plain
                style="margin-right:16px"
                :style="buttonStyle"
            >{{ cancelText }}</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { debounce } from '@/utils/function';
    export default {
        props: {
            buttonStyle: {
                type: Object,
                default:() => ({
                    width: '56px',
                    height: '36px'
                })
            },
            showCancel: {
                type: Boolean,
                default: true
            },
            cancelText: {
                type: String,
                default: '取消'
            },
            confrimText: {
                type: String,
                default: '确认'
            },
            width: {
                type: String,
                default: '17%'
            },
            title: {
                type: String,
                default: '提示'
            },
            messageShow: {
                type: Boolean,
                default: false
            },
            // 图标类型
            // 1 : 红色
            // 2 : 蓝色
            // 3 : 橙色
            icon: {
                type: Number,
                default: 1
            },
            // 内容
            content: {
                type: String
            }
        },
        methods: {
            cancelMessage:debounce(function(){ 
                this.$emit("cancel",this.messageShow);
            }),
            confrimMessage:debounce(function(){
                this.$emit("confrim",this.messageShow);
            })
        }
    }
</script>
<style scoped>
    .header,
    .header_left{
        display: flex;
        align-items: center;
    }
    .header{
        justify-content: space-between;
    }
    .header .header_left img{
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    .close{
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-right: 0;
    }
    .title{
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
        color:#000;
    }
    .footer{
        display: flex;
        flex-direction: row-reverse;
    }
    .content{
        margin: 15px 0;
        font-size: 14px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
        color: #000;
        opacity: .7;
    }
    /* 重写样式 */
    div/deep/.el-dialog__header{
        padding: 0;
    }
    div/deep/.el-dialog__body{
        padding: 18px;
    }
    div/deep/.el-dialog{
    margin-top: 38vh !important;
    border-radius: 4px;
    min-width: 296px !important;
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Layout from "../layouts/index.vue";

/**
 * 重写路由的push方法
 * 解决，相同路由跳转时，报错
 * 添加，相同路由跳转时，触发watch (针对el-menu，仅限string方式传参，形如"view?id=5")
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/login",
    children: [
      {
        path: "/home",
        name: "home",
        component: HomeView,
      },
      //业务系统管理
      {
        path: "/businessManagement/businessManagement",
        component: () =>
          import("../views/businessManagement/businessManagement.vue"),
        meta: { title: "业务系统管理" },
      },
      //统计分析
      {
        path: "/statisticalAnalysis/user",
        component: () => import("../views/statisticalAnalysis/user.vue"),
        meta: { title: "使用方" },
      },
      {
        path: "/statisticalAnalysis/channelQuotient",
        component: () =>
          import("../views/statisticalAnalysis/channelQuotient.vue"),
        meta: { title: "通道商" },
      },
      // 记录管理
      {
        path: "/record/send",
        component: () => import("../views/record/send.vue"),
        meta: { title: "发送记录" },
      },
      {
        path: "/record/recharge",
        component: () => import("../views/record/recharge.vue"),
        meta: { title: "充值记录" },
      },
      //模板管理
      {
        path: " /template/template",
        component: () => import("../views/template/template.vue"),
        meta: { title: "模板管理" },
      },
      //短信模板
      {
        path: " /template/textTemplate",
        component: () => import("../views/template/textTemplate.vue"),
        meta: { title: "短信模板" },
      },
      //app模板
      {
        path: " /template/appTemplate",
        component: () => import("../views/template/appTemplate.vue"),
        meta: { title: "APP模板" },
      },
      // 通道管理
      {
        name: "ChannelDealer",
        path: "/passage/channelDealer",
        component: () => import("../views/passage/channelDealer.vue"),
        meta: { title: "通道商配置" },
      },
      {
        name: "ChannelAssignment",
        path: "/passage/channelAssignment",
        component: () => import("../views/passage/channelAssignment.vue"),
        meta: { title: "通道配置" },
      },
      // 异常通知页面
      {
        name: "notification",
        path: "/notification",
        component: () => import("../views/notification/notification.vue"),
        meta: { title: "通道配置" },
      },
      // 敏感词管理
      {
        name: "sensitive",
        path: "/sensitive",
        component: () => import("../views/notification/sensitive.vue"),
        meta: { title: "敏感词管理" },
      },
     
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/login/login.vue"),
    meta: { title: "登录" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

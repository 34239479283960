export default [
  {
    id: 1,
    name: "通道管理",
    icon: "icon-menu_5Template",
    path: '/passage',
    children: [
      {
        id: 2,
        name: "通道商配置",
        path: "/passage/channelDealer",
      },
      {
        id: 3,
        name: "通道配置",
        path: "/passage/channelAssignment",
      },
    ],
  },
  // {
  //   id: 4,
  //   name: "业务系统管理",
  //   path: "/businessManagement/businessManagement",
  //   icon: "icon-a-menu_2Businesssystem",
    
  // },
  {
    id: 5,
    name: "记录管理",
    icon: "icon-menu_3record",
    path: '/record',
    children: [
      {
        id: 6,
        name: "发送记录",
        path: "/record/send",
      },
      {
        id: 7,
        name: "充值记录",
        path: "/record/recharge",
      },
    ],
  },
  {
    id: 8,
    name: "统计分析",
    icon: "icon-menu_4statistics",
    path: '/statisticalAnalysis',
    children: [
      {
        id: 9,
        name: "使用方",
        path: "/statisticalAnalysis/user",
      },
      {
        id: 10,
        name: "通道商",
        path: "/statisticalAnalysis/channelQuotient",
      },
    ],
  },
  {
    id: 11,
    name: "模板管理",
    icon: "icon-menu_5Template",
    path: "/template",  
    children:[
      {
        id: 12,
        name: "短信模板",
        path: "/template/textTemplate",
      },
      {
        id: 13,
        name: "APP模板",
        path: "/template/appTemplate",
      },

    ]
  },
  {
    id: 14,
    name: "敏感词管理",
    path: "/sensitive",
    icon: "el-icon-message",     
  },
];

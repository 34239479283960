import axios from 'axios';
import { Loading, Message  } from 'element-ui';
// 导出
  export async function  exportData(url, searchForm, filename) {
    return new Promise(async (resolve,reject)=>{
      const loadingInstance = Loading.service({
        lock: true,
        text: "请耐心等待,导出订单数据中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    
      try {
        let res = await axios.post(
          url,
          searchForm,
          {
            responseType: "blob", // axios将返回 文件流 数据转换为 blob对象
          }
        );
          loadingInstance.close();
        let blob = new Blob([res], {
          type: "application/octet-stream;charset=UTF-8",
        }); // 创建blob对象
        let elink = document.createElement("a"); // 创建a标签
        elink.download = filename + ".xlsx"; // 设置下载文件名
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob); // 根据blob对象 创建url链接
        document.body.appendChild(elink); // 将a标签添加的body中
        elink.click(); // 触发a标签的点击事件
        document.body.removeChild(elink); // 移除body中的a标签
        resolve()
      } catch (error) {
        reject(error)
      }
    })    
  }
  //防抖
  export function debounce(fun, delayTime=300) {
    // 封装防抖函数
    let time = null;
    return function () {
      if (time !== null) {
        clearTimeout(time);
      }
      time = setTimeout(() => {
        fun.call(this);
      }, delayTime);
    };
  }
  
  export function doSubString(str, length = 10) {
    return str?.length > length ? `${str?.substr(0,length)}...` : str
  }
  // 判断正整数、负整数
  export function isNegativeInteger(x) {
      if (x == parseInt(x) && x < 0) {
          // 负整数
          return true
      } else if (x == parseInt(x) && x > 0) {
          // 正整数
          return true
      } else if (0 == parseInt(x)) {
          return true
      } else {
          return false
      }
  }
  // 转换日期格式
  export function  getDate(date, type = 'com') {
    let year = (date.getFullYear()).toString()
    let month = (date.getMonth() + 1).toString()
    let day = (date.getDate()).toString()
    let hour = (date.getHours() + 1).toString()
    let min = (date.getMinutes()).toString()
    let sec = (date.getSeconds()).toString()
    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }
    if (hour.length < 2) hour = '0' + hour
    if (min.length < 2) min = '0' + min
    if (sec.length < 2) sec = '0' + sec
    if (type == 'com') {
      return `${year}-${month}-${day}`
    }else {
      return `${year}-${month}-${day} ${hour}:${min}:${sec}`
    }
  
  }

  // 将 '' undefied null 等不安全的属性删除
  export function clearNotSafeValue(obj) {
    const allowValue = [0]
    for (const key in obj) {
      if (!obj[key] && !allowValue.includes(obj[key])) {
        obj[key] = undefined
      }
    }
    return obj
  }

  export function pageMessage(options) {
    Message({
        iconClass: `iconfont icon-info_Warning color_${options.type}`,
        customClass: `page_msg ${options.type}`,
        showClose: options.showClose,
        message: options.message
    });
  }

  export  function validataForm (ctx, ref) {
    return new Promise((resolve, reject)=> {
      ctx.$refs[ref].validate((valid) => {
        console.log('表单验证结果', valid)
          if (!valid) {
              reject()
          } else {
            resolve()
          }
      });
    })
  }
<template>
  <div>
      <AiTable
        :extend="extend"
        :columns="columns"
        :tableData="list"
        :total="159"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
      ></AiTable>
  </div>
</template>

<script>
import AiTable from "@/components/aiTable/aiTable.vue";
// import { wareHouse } from "@/api/carState";
// import axios from "axios";
export default {
  name: "",
  props: {},
  data() {
    return {
      chooseDate: "date",
      chooseMonth: "month",
      //公司列表
      companyData: [],
      //业务列表
      bussinessData: [],
      options: [],
      current: 1,

      searchForm: {
        valueDay: "",
        valueMonth: "",
        valueYearStart: "",
        valueYearEnd: "",

        businessType: "",
        businessTypeName: "",
        companyId: "",
        endTime: "",
        pageNum: 1,
        pageSize: 10,
        queryDate: "",
        queryEndTime: "",
        queryStartTime: "",
        startTime: "",
        timeFormat: "",
        timeUnit: 3,
      },
      //table相关数据
      extend: [
        {
          type: "index",
          label: "序号",
          width: "80",
          align: "center",
        },
      ],
      list:[{
        date:'123',
        companyName:'213131',
        businessTypeName:'1321321',
        leaseArea:'1321321',
        rentAmount:'111'
      },
      {
        date:'123',
        companyName:'213131',
        businessTypeName:'1321321',
        leaseArea:'1321321',
        rentAmount:'111'
      }],
      columns: [
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "公司名称",
          prop: "companyName",
        },
        {
          label: "业务类型",
          prop: "businessTypeName",
        },
        {
          label: "固定仓在营面积(m²)",
          prop: "leaseArea",
        },
        {
          label: "固定仓收入(元)",
          prop: "rentAmount",
        },

      ],
      sumList: [
        {
          label: "6",
          value: 257,
        },
        {
          label: "7",
          value: 257,
        },
        {
          label: "8",
          value: "-",
        },
        {
          label: "9",
          value: 257,
        },
      ],
      currentPage: 1,
      totalNum: 0,
    };
  },
  components: {
    AiTable,
  },
  methods: {
    handleSizeChange(val){
      console.log(val)
    },
    handleCurrentChange(val){
      console.log(val)
    }
  }
};
</script>

<style scoped>
#title {
  height: 36px;
  font-size: 16px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #3a3f4f;
  line-height: 36px;
  margin-bottom: 12px;
}
.topSearch {
  height: auto;
  width: 100%;
  display: flex;
}
#tableBox {
  /* width: 1640px; */
  /* height: 326px; */
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 20px 20px 20px;
}
#tableTitle > div:nth-child(2) {
  cursor: pointer;
}
#tableTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
.el-icon-info {
  width: 16px;
  height: 16px;
  opacity: 0.7;
  color: #bccce0;
  margin-left: 10px;
  cursor: pointer;
}
#tableTitle > div:first-child > span {
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  color: #939699;
  line-height: 16px;
  margin-top: 10px;
}
#tableTitle > div:last-child {
  width: 84px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #dfe3e8;
  border-radius: 2px;
  padding: 6px 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
#tableTitle > div:last-child > img {
  width: 24px;
  height: 24px;
}
#tableTitle > div:last-child > div {
  width: 28px;
  height: 19px;
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  color: #313233;
  line-height: 24px;
  /* margin-left: 8px; */
}
#paginationBox {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 18px 24px;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 10px;
}
#paginationBox > div {
  height: 22px;
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
  font-weight: normal;
  color: #626466;
  line-height: 22px;
}
/**/
.changeType {
  width: 152px;
  height: 38px;
  background: #fafbfc;
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.typeItem {
  width: 48px;
  height: 34px;
  background: #ffffff;
  border-radius: 2px;
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  color: #999999;
  line-height: 34px;
  cursor: pointer;
}
.action {
  width: 48px;
  height: 34px;
  background: #ffffff;
  border-radius: 2px;
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  color: #1a7dff;
  line-height: 34px;
  cursor: pointer;
}
.year :deep .el-date-editor.el-input {
  width: 120px;
}
:deep .el-select {
  width: 138px;
}
.searchButton {
  display: flex;
}
.searBtn {
  margin-left: 20px;
  width: 68px;
  height: 36px;
  background: #197dff;
  border-radius: 4px;
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  line-height: 36px;
  cursor: pointer;
}
.clear {
  margin-left: 10px;
  width: 68px;
  height: 36px;
  background: #e3efff;
  border: 1px solid #197dff;
  border-radius: 4px;
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  color: #197dff;
  line-height: 36px;
  cursor: pointer;
}
.open {
  font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
  font-weight: normal;
  color: #197dff;
  cursor: pointer;
}
/** 查询样式end*/
</style>
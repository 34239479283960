<template>
  <el-header class="page_header">
    <el-dropdown @command="handleCommand" class="login_msg">
      <span class="login_user_msg">
        <img class="lum_item user_icon" src="@/assets/menu_user.png" alt="" />
        <span class="lum_item login_msg-nick">{{ userInfo.realname }}</span>
        <i class="lum_item el-icon-caret-bottom"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="notification"
          ><span class="logout-text">异常通知设置</span></el-dropdown-item
        >
        <el-dropdown-item command="password"
          ><span class="logout-text">修改密码</span></el-dropdown-item
        >
        <el-dropdown-item command="logout"
          ><span class="logout-text">退出登录</span></el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 退出登录 start -->
    <pageMessageBox
      :messageShow="logoutMessage.messageVisible"
      :icon="logoutMessage.icon"
      :content="logoutMessage.content"
      :title="logoutMessage.title"
      :width="logoutMessage.width"
      :showCancel="logoutMessage.showCancel"
      :buttonStyle="logoutMessage.buttonStyle"
      :confirmText="logoutMessage.confirmText"
      @cancel="logoutMessage.messageVisible = false"
      @confrim="doLogout"
    />
    <!-- 退出登录 end -->

    <!-- 修改密码 start -->
    <el-dialog title="修改密码" :visible.sync="passwordVisible" width="20%" @close="cancelPassword">
      <el-form
        :model="form"
        ref="form"
        label-position="left"
        label-width="80px"
        :rules="passwordRules"
      >
        <el-form-item label="原密码" prop="password">
          <el-input
            v-model="form.password"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelPassword">取 消</el-button>
        <el-button type="primary" @click="updatePassword">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改密码 end -->
  </el-header>
</template>
<script>
import { UserService } from "@/api/index";
import pageMessageBox from "@/components/pageMessageBox/pageMessageBox.vue";
export default {
  data() {
    return {
      userInfo: {},
      logoutMessage: {
        confirmText: "确定",
        cancelText: "取消",
        showCancel: true,
        width: "256px",
        messageVisible: false,
        icon: 2,
        content: "请确认是否要退出登录。",
        title: "退出登录",
      },
      passwordVisible: false,
      form: {
        password: "",
        newPassword: "",
      },
      passwordRules: {
        password: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    pageMessageBox,
  },
  methods: {
    updatePassword() {
      let params = {
        account: this.userInfo.account,
        password: this.form.newPassword,
        oldPassword:this.form.password,
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          UserService.updatePassword(params).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "密码修改成功",
                type: "success",
              });

              this.passwordVisible = false;
            }else{
              this.$message({
                message: res.message,
                type: "error",
              });
              this.passwordVisible = true;
            }
          });
        }
      });
      // this.passwordVisible = false;
    },
    cancelPassword() {
      this.form.password = "";
      this.form.newPassword = "";
      this.passwordVisible = false;
    },
    handleCommand(e) {
      if (e == "logout") {
        this.logoutMessage.messageVisible = true;
      } else if (e == "password") {
        this.passwordVisible = true;
      } else if (e == "notification") {
        this.$router.push("/notification");
      }
    },
    doLogout() {
      UserService.loginOut().then((res) => {
        window.localStorage.setItem("token", "");
      });
      this.logoutMessage.messageVisible = false;
      window.localStorage.setItem("userInfo", "{}");
      this.$router.replace("/login");
    },
  },
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  },
};
</script>
<style lang="scss" scoped>
.page_header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px !important;
  background: #ffffff;
}
.login_msg {
  margin-left: auto;
  color: #197dff;
}
.login_msg_nick {
  margin-right: 8px;
  margin-left: 8px;
}
.login_user_msg {
  display: flex;
  align-items: center;
}
.user_icon {
  width: 24px;
  height: 24px;
}
.lum_item {
  cursor: pointer;
  margin-left: 8px;
}
.logout-text {
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
  font-weight: normal;
  color: #000000;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import '/public/theme/index.css';
import '/public/css/public.css';
import '/public/css/icon/iconfont.css';
import * as echarts from 'echarts';
import utils from '@/plugins/util.js'


Vue.config.productionTip = false
Vue.prototype. $echarts = echarts;
Vue.use(ElementUI);
Vue.use(echarts);
Vue.use(utils);
new Vue({
  router,
  store,
  beforeCreate(){
    Vue.prototype.$bas = this;
  },
  render: h => h(App)
}).$mount('#app')

<template>
    <el-menu 
        ref="menu"
        class="menu-list" 
        :unique-opened="false"
        :router="true"
        :default-active="$route.path"
        :default-openeds="opendeds"
     >
        <div v-for="menu in menus" :key="menu.path">
            <el-submenu 
                :class="{ parent_menu: parent_menuIds.includes(menu.id) }" 
                :key="menu.id+''" 
                :index="menu.id+''" 
                v-if="menu.children && menu.children.length > 0">
                <template slot="title">                
                    <span class="menu_icon" >
                        <i v-if="menu.icon" class="menu_icon_content iconfont" :class="[menu.icon]"></i>
                    </span>
                    <span>{{ menu.name  }}</span>
                </template>
                <Menu :menus="menu.children" />
            </el-submenu>
            <el-menu-item 
                v-else
                @click="changeMenu(parent_menuIds.includes(menu.id))" 
                :class="{ parent_menu: parent_menuIds.includes(menu.id) }" 
                :index="menu.path">
                <span class="menu_icon" v-if="menu.name==='敏感词管理'">
                    <i v-if="menu.icon"  :class="[menu.icon]" style="color: #eee;font-size: 26px;"></i>
                </span>
                <span class="menu_icon " v-else>
                    <i v-if="menu.icon" class="menu_icon_content iconfont" :class="[menu.icon]"></i>
                </span>
                <span slot="title">{{ menu.name }}</span>
            </el-menu-item>
        </div>

    </el-menu>
</template>
<script>
    import menuMock from '@/mock/menu.js'
    export default {
        name: 'Menu', // 声明名称 Menu 可以使该组件能够递归调用
        data() {
            return {
                allMenus: menuMock,
                opendeds: []     
            }
        },
        props: {
            menus: {
                type: Array
            }
        },
        computed: {
            parent_menuIds() {
                return this.allMenus.map(item=>item.id)
            }
        },
        watch:{
            '$route.path':{
                handler: function(newVal,oldVal){
                    // 展开菜单与路由匹配
                    let opendeds = []
                    this.allMenus.forEach((item,index)=>{
                        if(item.children){
                            item.children.forEach((data) => {
                                if(data.path === newVal){
                                    opendeds.push(String(item.id))
                                }
                        })
                        }
                    })
                    this.opendeds = opendeds
                    // console.log('opendes', this.opendeds )
                },
                immediate: true
            }
        },
        methods: {
            changeMenu(type) {
                if (type) {
                    this.opendeds = []
                }
            }
        }
    }
</script>
<style lang="scss" scoped>

.el-menu {
    background: transparent;
    border-right: none;
}
.menu_icon  {
    display: inline-block;
    width: 28px;
    margin-right: 8px;
}
.menu_icon_content {
    width: 28px;
    font-size: 28px;
    color: inherit;
    font-weight: normal;
}
.icon_box{
    font-size: 28px;
    color: inherit;
    font-weight: normal;

}

// 一级菜单
:deep(.parent_menu .el-submenu__title), :deep(.el-menu-item.parent_menu) {
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 18px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
    font-weight: normal;
    color: #ffffffe6;
    box-sizing: border-box;
    width: 256px;
    height: 48px;
    margin: 0 auto;
    margin-bottom: 16px;
    border-radius: 2px;
}

:deep(.parent_menu.is-opened .el-submenu__title), :deep(.el-submenu__title:hover), :deep(.parent_menu.is-active){
    background-color:rgb(108,171,255);
    font-weight: 700;
    color: #ffffff;
}
:deep(.el-submenu__icon-arrow) {
    font-size: initial;
    font-weight: 700;
    color: inherit
}
// 子菜单
:deep(.el-submenu .el-menu) {
    background: transparent;
}
.el-menu-item {
    width: 256px;
    height: 48px;
    margin: 0 auto;
    color: #ffffffe6;
    margin-bottom: 8px;
    border-radius: 2px;
    font-size: 16px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-MicrosoftYaHei;
    font-weight: normal;

}
:deep(.el-menu-item){
    transition: all .8s
}

.el-menu-item:hover {
    background-color:rgb(108,171,255);
    font-weight: 700;
    color: #ffffff;
}
:deep(.el-menu-item:focus:not(.parent_menu)), :deep(.el-menu-item.is-active:not(.parent_menu)) {
    background-color:#fff;
    font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
    font-weight: 700;
    color: #197dff;
    outline: 0;
}


</style>
<template>
    <el-container class="layout-vertical">
        <Aside />
        <el-container class="layout-vertical-content">
            <Header />
            <Main />
        </el-container>
    </el-container>
</template>
<script>
	import Aside from './aside/index.vue'
	import Header from './header/index.vue'
    import Main from './main/index.vue'
    export default {
        data() {
            return {

            }
        },
        components: {
            Aside,
            Header,
            Main
        }
    }
</script>
<style lang="scss" scoped>
    .layout-vertical {
        display: flex;
    }
    .layout-vertical-content {
        display: flex;
        flex-direction: column;
    }
</style>
import { request } from "../http/index";
/**
 * 按照模块导出
 */
 export class UserService {
     static async login (params) {
        return request ('/login/account_login',params,'post')
     }
     static async loginOut (params) {
        return request ('/login/sign_out',params,'get')
     }
   //   修改密码
   static async updatePassword (params) {
      return request ('/login/updatePassWord',params,'post')
   }

 }
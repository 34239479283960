<template>
    <el-aside class="menu_container">
        <div class="menu">
            <div class="menu_logo">
                <img src="@/assets/logo.png" mode="" />
            </div>
            <Menu :menus="menus" />
        </div>
    </el-aside>
</template>
<script>
import Menu from './menu/index.vue'
import menuMock from '@/mock/menu.js'
export default {
    data() {
        return {
            menus: menuMock
        }
    },
    components: {
        Menu
    }
}
</script>
<style lang="scss">
// min-width1200
.menu_container {
    position: fixed;
    top: 0;
    left: 0;
    flex-shrink: 0;
    width: 272px!important;
    min-height: 100vh;
    height: auto;
    background: url(@/assets/menu_bg.png) no-repeat;
    background-size: cover;
}

.menu_logo {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 20px 0;
    padding-right: 92px;
    background: transparent;
    font-size: 30px;
    font-family: PangMenZhengDao, PangMenZhengDao-PangMenZhengDao;
    font-weight: normal;
    color: #fff;
    border-bottom: 1px solid #eeeeee4d;
    margin-bottom: 24px

}
</style>
import { doSubString, pageMessage, debounce } from '@/utils/function'

const utils = {
    doSubString,
    pageMessage,
    debounce
}

export default {
  install(app) {
   for (const key in utils) {
      app.prototype[key] = utils[key]
   }
  }
}
